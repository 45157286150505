import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../components";
import langConfig from "../sql/config.json";
import {
  setBlur,
  setModal,
} from "../store/actions";

export const Home = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { lang, blur } = useSelector(
    (state) => state.userAppReducer
  );
  const [optionsList, setOptionsList] = useState([]);
   const [school, setSchool] = useState(null);
  const [educationType, setEducationType] = useState(null);
  const [division, setDivision] = useState(null);
  const [schoolListActive, setSchoolListActive] = useState(false);
  const [divisionsListActive, setDivisionsListActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [divisionsList, setDivisionsList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [educationListActive, setEducationListActive] = useState(false);

  const [checkLogin, setCheckLogin] = useState(false);
     const token = localStorage.getItem("token");

  useEffect(() => {
    getSchools();
    dispatch(setBlur(false));
    dispatch(setModal(false));
  }, []);

  const getSchools = async () => {
    try{
      await axios
      .get(
        "https://emmobank.com/api/get_grades_with_divisions",
        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        setSchoolList(response.data.grades);
      });
    }catch(e){}
    
  };

  const optionModaleFunction = (txt, list) => {
    if (list.length) {
      list[0].title = txt;
    }
    setOptionsList(list);
    if (txt === "schoolList") {
      setSchoolListActive(!schoolListActive);
    } else if (txt === "divisionsList") {
      setDivisionsListActive(!divisionsListActive);
    } else if (txt === "educationList") {
      setEducationListActive(!educationListActive);
    }
  };

  const optionSelected = (txt, props) => {
    if (txt === "schoolList") {
      setEducationList(props.educational_types);
      setDivisionsList(props.divisions);
      setSchool(props);
      setEducationType(null);
      setDivision(null);
    } else if (txt === "divisionsList") {
      setDivision(props);
    } else if (txt === "educationList") {
      setEducationType(props);
      setDivision(null);
    }
    setSchoolListActive(false);
    setDivisionsListActive(false);
    setEducationListActive(false);
  };

  const saveMyCriteria = () => {
    const divisionId = division ? division.id : 0;
    localStorage.setItem("grade_id", school.id)
    history("/subjects/" + school.id + "/" + divisionId);
  };

  return (
    <div
      dir={"rtl"}
      className={`w-full h-screen p-0 bg-gray-100 flex flex-col justify-start} md:max-w-[875px] md:m-auto overflow-hidden`}
    >
      <div>
        <NavBar
          menu={true}
          qr={false}
          back={false}
          title={true}
          titleText={"تدريبات 2024 - 2025"}
        />
        {checkLogin === true ? (
          <div className="w-full h-full min-h-[30vh] flex flex-row justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10 animate-spin"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        ) : !loading ? (
          <>
            <div
              className={`flex ${blur === true ? "blur-sm" : ""
                } w-full h-[90vh] px-4 py-6  overflow-auto`}
            >
              <div className="w-full flex flex-col py-3 px-6 justify-between">
                <div className="flex w-full flex-col gap-0 md:max-w-screen-sm md:m-auto">
                  <img
                    className={`h-auto w-[160px] mb-10 flex m-auto `}
                    alt="GPS education"
                    src="./bg/GPS-Logo.svg"
                  />
                  <div
                    className={`w-full h-fit flex-col gap-2 justify-start mt-2`}
                  >
                    <h3 className="text-lg text-[#151048] font-[Almarai] font-bold mb-2">
                      السنة الدراسية
                    </h3>
                    <div className="flex w-full flex-col gap-1">
                      <button
                        onClick={() =>
                          optionModaleFunction("schoolList", schoolList)
                        }
                        className={`flex flex-row justify-between p-2 border bg-primary text-white
                             ${schoolListActive === true
                            ? "rounded-t-xl"
                            : "rounded-xl"
                          }`}
                      >
                        <b
                          className={` ${!school ? "text-gray-300" : ""
                            } font-[Almarai] font-normal`}
                        >
                          {!school ? "اختر الصف الدراسي" : school?.name["ar"]}
                        </b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          {schoolListActive === true ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 15.75l7.5-7.5 7.5 7.5"
                            />
                          ) : (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          )}
                        </svg>
                      </button>
                      {schoolListActive === true ? (
                        <div
                          className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}
                        >
                          {schoolList.map((e, index) => (
                            <button
                              key={index}
                              onClick={() => optionSelected("schoolList", e)}
                              className="flex w-full py-1 px-2 font-[Almarai] text-[#151048]"
                            >
                              {e.name["ar"]}
                            </button>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* start education type */}
                  {school && (
                    <div
                      className={`w-full h-fit flex-col gap-2 justify-start mt-2`}
                    >
                      <h3 className="text-lg text-[#151048] font-[Almarai] font-bold mb-2">
                        نوع الدراسة{" "}
                      </h3>
                      <div className="flex w-full flex-col gap-1">
                        <button
                          onClick={() =>
                            optionModaleFunction("educationList", educationList)
                          }
                          className={`
                                                    flex flex-row justify-between p-2 border bg-primary text-white
                                                    ${educationListActive ===
                              true
                              ? "rounded-t-xl"
                              : "rounded-xl"
                            }
                                                `}
                        >
                          <b
                            className={` ${!educationType ? "text-gray-300" : ""
                              } font-[Almarai] font-normal`}
                          >
                            {!educationType
                              ? "اختر نوع الدراسة"
                              : educationType?.name["ar"]}
                          </b>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            {educationListActive === true ? (
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 15.75l7.5-7.5 7.5 7.5"
                              />
                            ) : (
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            )}
                          </svg>
                        </button>
                        {educationListActive === true ? (
                          <div
                            className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}
                          >
                            {educationList.map((e, index) => (
                              <button
                                key={index}
                                onClick={() =>
                                  optionSelected("educationList", e)
                                }
                                className="flex w-full py-1 px-2 font-[Almarai] text-[#151048]"
                              >
                                {e.name["ar"]}
                              </button>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}

                  {/* start School divisions */}
                  {educationType && divisionsList.length ? (
                    <div
                      className={`w-full h-fit flex-col gap-2 justify-start mt-4 `}
                    >
                      <h3 className="text-lg text-[#151048] font-[Almarai] font-bold mb-2">
                        الشعبة
                      </h3>
                      <div className="flex w-full flex-col gap-1">
                        <button
                          onClick={() =>
                            optionModaleFunction("divisionsList", divisionsList)
                          }
                          className={`
                                                    flex flex-row justify-between p-2 border bg-primary text-white
                                                    ${divisionsListActive ===
                              true
                              ? "rounded-t-xl"
                              : "rounded-xl"
                            }
                                                `}
                        >
                          <b
                            className={` ${!division ? "text-gray-300" : ""
                              } font-[Almarai] font-normal`}
                          >
                            {!division
                              ? langConfig.config["ar"].yourDivision
                              : division?.name["ar"]}
                          </b>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            {divisionsListActive === true ? (
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 15.75l7.5-7.5 7.5 7.5"
                              />
                            ) : (
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            )}
                          </svg>
                        </button>
                        {divisionsListActive === true ? (
                          <div
                            className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}
                          >
                            {divisionsList.map((e, index) => (
                              <button
                                key={index}
                                onClick={() =>
                                  optionSelected("divisionsList", e)
                                }
                                className="flex w-full py-1 px-2 font-[Almarai]"
                              >
                                {e.name["ar"]}
                              </button>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* end divisions */}
                </div>
                <div className="flex w-full flex-col gap-5 md:max-w-screen-sm md:m-auto">
                  
                  {school &&
                    educationType &&
                    ((divisionsList.length && division) || (divisionsList.length === 0))  ? (
                    <button
                      onClick={() => saveMyCriteria()}
                      className={`${school && educationType
                          ? "bg-primary text-white border-0"
                          : "border border-primary text-[#151048] bg-white mt-"
                        } font-[Almarai] rounded-full text-md w-full p-3 my-4  md:max-w-screen-sm md:m-auto`}
                    >
                      {langConfig.config[lang]?.Continue}
                    </button>
                  ) : (
                    <div
                      className={`text-center text-[#151048] bg-gray-300 font-[Almarai] rounded-full text-md w-full p-3 my-4  md:max-w-screen-sm md:m-auto`}
                    >
                      {langConfig.config[lang]?.Continue}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
