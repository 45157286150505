import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalScreen from "./ModalScreen";
import { showScreenModal, setScreen } from "../../store/actions";
import domtoimage from 'dom-to-image';

const Screen = ({ children, callback }) => {
  const ref = useRef(null);
  const [MyImg, setMyImg] = useState("");
  const [quid, setQuid] = useState("");
  const { screenValue, screenModalValue } = useSelector(
    (state) => state.userAppReducer
  );
  const dispatch = useDispatch();

  const captureScreen = async () => {
    try {
      const questionElement = document.getElementById("question");
      
      if (questionElement) {
        const dataUrl = await domtoimage.toPng(questionElement, {
          quality: 1,
          style: {
            transform: 'scale(1)',
            transformOrigin: 'top left'
          }
        });

        setMyImg(dataUrl);
      }
    } catch (error) {
      console.error("Error capturing or sending screenshot:", error);
    } finally {
      dispatch(setScreen(false));
      dispatch(showScreenModal(true));
    }
  };

  useEffect(() => {
    if (screenValue) {
      captureScreen();
    }
  }, [screenValue]);

  const { questionsList, currentQues } = useSelector(
    (state) => state.userAppReducer
  );

  useEffect(() => {
    if (questionsList?.id) {
      setQuid(questionsList?.id || 0);
    } else if (questionsList[currentQues]?.question === undefined) {
      setQuid(questionsList[currentQues]?.id || 0);
    } else {
      setQuid(questionsList[currentQues]?.question.id || 0);
    }
  }, [screenModalValue]);

  return (
    <div
      ref={ref}
      className={`w-full justify-center p-0 bg-gray-100 flex flex-col font-[Almarai] max-w-[875px] m-auto`}
    >
      {children}
      <ModalScreen MyImg={MyImg} quid={quid} />
    </div>
  );
};

export default Screen;